.paginator-mobile {
  user-select: none;
  min-width: 2.625rem;
  width: fit-content;
  height: 2.5rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #CBE3C2;

  &__page-item {
    cursor: pointer;
    width: 2.375rem;
    height: 2.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    font-weight: 900;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
    color: #102807;

    &:last-child,
    &:first-child {
      background: transparent;
    }
  }
  &__active {
    border: 1px solid #55b190;
    color: #55b190;
  }

  &__arrow-left-container,
  &__arrow-right-container {
    width: 2.625rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-left,
  &__arrow-right {
    width: 1.9375rem;
    height: 1.875rem;
    display: flex;
  }
  &__arrow-right {
    transform: rotate(180deg);
  }
}
