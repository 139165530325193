.bet-history-filter-mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  background: #dbeed6;

  &__items {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.1875rem 0.5rem;
    margin: 0 0 0.4375rem 0;
  }
  &__item-label {
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 0.75rem;
    color: #071728;
  }
  &__item-input {
    width: 100%;
    height: 2.5rem;
  }

  &__item-check-boxes1,
  &__item-check-boxes2 {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.0625rem 0;
  }
  &__item-check-boxes2 {
    margin: 0 0 0.4375rem 0;
  }
  &__item-check-boxes-label {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 0.4375rem 0;
    text-align: start;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #fff;
  }
  &__item-check-boxes-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.4375rem 0;
    margin: 0 0 0 0.625rem;
  }

  &__check-box-container {
    display: flex;
    align-items: flex-start;
  }
  &__check-box {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin: 0 0.5625rem 0 0;
  }
  &__check-box-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #fff;
  }

  &__item-selector {
    width: 100%;
    margin: 0 0 1.0625rem 0;
  }
  &__item-selector-label {
    width: 100%;
    justify-self: flex-start;
    align-self: center;
    margin-bottom: 0.1875rem;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #fff;
  }
  &__item-selector-input {
    height: 2.5rem;
    width: 100%;
  }

  &__checkboxes {
    margin: 0 0 1.0625rem 0;
  }
  &__title {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: 0.875rem;
    color: #55b169;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem 1.0625rem;
  }
  &__checkbox-item {
    display: flex;
    align-items: center;
  }
  &__checkbox {
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin: 0 0.5625rem 0 0;
  }
  &__checkbox-item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #071728;
  }

  &__item-dates {
    display: flex;
    flex-direction: column;
    margin: 0 0 0.4375rem 0;
  }
  &__item-dates-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    color: #55b169;
  }
  &__item-dates-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.1875rem 0.5rem;
  }
  &__item-dates-label {
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 0.75rem;
    color: #071728;
  }
  &__item-dates-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    border-radius: 0.3125rem;
    overflow: hidden;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}
