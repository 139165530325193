.user-edit {
  &__content {
    padding: 0.625rem;
    background: #CBE3C2;
  }

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 1.3125rem 0;
  }
  &__field {
    width: 100%;
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin: 0 0 0.4375rem 0;
    }
  }
  &__field-title {
    margin-bottom: 3px;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #102807;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__payment-button {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 1rem;
  }
  &__payment-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }

  &__reset-button {
    width: 100%;
    height: 2.5rem;
    margin-bottom: 1rem;
  }
  &__reset-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    color: #102807;
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 0.625rem;
  }

  &__ban-button,
  &__unban-button {
    width: 100%;
    height: 2.5rem;
  }
  &__ban-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }
  &__unban-button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }
}
