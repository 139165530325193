.password-pop-up {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);

  &__body {
    max-width: 339px;
    width: 90%;
    padding: 3.0625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    border: 1px solid #CBE3C2;
    box-shadow: 0 0.3125rem 0.625rem rgba(46, 66, 98, 0.05);
  }
  &__header {
    margin: 0 0 1.1875rem 0;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #102807;
  }
  &__password {
    margin: 0 0 1.1875rem 0;
    font-weight: 600;
    font-size: 2.625rem;
    line-height: 3.0625rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #102807;
  }

  &__button {
    width: 9.375rem;
    height: 40px;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}
