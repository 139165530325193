.bet-types-filter {
  width: 90%;
  margin: 1rem auto 0 auto;

  .select {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 2.5rem;
    border: 1px solid #fff;
    box-sizing: border-box;
    background: #fff;
    color: #102807;
    border-radius: 5px;

    &_open {
      border-radius: 5px 5px 0 0;
    }

    &__items-list {
      top: 2.3rem;
      width: 100.2%;
      background: #fff;
      border-radius: 0 0 5px 5px;
    }

    &__item{
      height: 2.5rem;
    }

    &__arrow { width: 1rem }
  }

  &__filter-row {
    width: 100%;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__text {
    width: 100%;
    font-size: 1rem;
    margin-bottom: .2rem;
    color: #102807;
  }
}