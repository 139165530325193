.auth {
  flex-grow: 2;
  display: flex;
  flex-direction: column;

  &__title {
    background: #42C903;
    width: 100%;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #ffffff;
  }
  &__title-text {
    font-size: 1rem;
    color: #102807;
    font-weight: bold;
    margin-bottom: 0.625rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.625rem;
  }

  &__field {
    &:not(:last-child) {
      margin: 0 0 0.4375rem 0;
    }
  }
  &__field-title {
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #102807;
  }
  &__field-input {
    height: 2.5rem;
    width: 100%;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0.4375rem 0 0 0;
  }
}
