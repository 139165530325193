.bets {
  padding-top: 1rem;
  background: #EEEEEE;
  &__user-id-input{
    margin: 0 auto;
    width: 90%;
    color: #102807;
    input{
      margin-top: .2rem;
      border-radius: 5px;
    }
  }
  &__pagination{
    width: 100%;
  }
}