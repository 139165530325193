.wrapped-react-input {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding-left: 1.3125rem;
  padding-right: 0.9375rem;
  border: 2px solid #cbd0dc;

  &_rounded {
    border-radius: 0.25rem;
  }

  &_sizable {
    &_default {
      height: 100%;
    }
    &_low {
      height: 2rem;
    }
    &_middle {
      height: 2.5rem;
    }
    &_high {
      height: 3rem;
    }
  }

  &__icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.6094rem;
    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    margin-right: 1.0625rem;
    width: 1.875rem;
    white-space: nowrap;
    font-size: 0.875rem;
    color: #9d9d9d;
  }

  &__calendar-icon {
    height: 100%;
    display: flex;
    align-items: center;
    &-svg {
      min-width: 0.6875rem;
      height: 0.4375rem;
      transition: transform 0.3s ease;
      transform: scaleY(-1);

      & path {
        fill: #747474;
      }
    }
  }

  .react-date-picker {
    width: 100%;
    &__wrapper {
      border: none;
      flex-grow: 1;
    }
    &__inputGroup {
      &__input,
      &__divider,
      &__leadingZero {
        font-size: 0.875rem;
        color: #102807;
        outline: none;
      }
    }
    &__button {
      padding: 0;
      outline: none;
    }
    &__clear-button {
      display: none;
    }
    &__calendar {
      z-index: 15;
    }
  }

  .react-date-picker--open
    .react-date-picker__wrapper
    .react-date-picker__button
    .wrapped-react-input__calendar-icon
    .wrapped-react-input__calendar-icon-svg {
    transform: scaleY(1);
  }
}