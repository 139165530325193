.header {
  min-height: 3.75rem;
  max-height: 3.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #216137;
  padding: 0 0.875rem 0 0.6875rem;

  &__logo-wrapper {
    flex-grow: 1;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
  }
  &__logo {
  }
  &__logo-icon {
    // width: 7.125rem;
    height: 2.6875rem;
    display: flex;
  }

  &__login-button-block {
    height: 2.25rem;
  }
  &__user {
    justify-self: flex-end;
  }
  &__user-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  &__user-id {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__user-id-title {
    margin: 0 0.25rem 0 0;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: right;
    color: #ffffff;
  }
  &__user-id-value {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: right;
    color: #ffffff;
  }
  &__user-balance {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__user-balance-value {
    margin: 0 0.25rem 0 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: right;
    color: #ffffff;
  }
  &__user-balance-currency {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: right;
    color: #ffffff;
  }
}
