@font-face {
  font-family: 'password';
  font-style: normal;
  font-weight: 400;
  src: url(./font/password.ttf);
}

.input {
  user-select: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &__native {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    border-radius: 0;
    border: 0 solid transparent;
    outline: none;
    font-family: 'Roboto', sans-serif;
    -webkit-appearance: none;

    &::placeholder {
      font-family: 'Roboto', sans-serif;
    }
    &:focus#{&}::placeholder {
      color: transparent;
    }

    &_type_password {
      padding: 0 3.625rem 0 0.625rem;
    }

    &_hasMeasurement {
      padding: 0 3.625rem 0 1.25rem;
    }

    &_activePassword {
      font-family: 'password';
    }
    &_activePassword#{&}::placeholder {
      font-family: 'Roboto', sans-serif;
    }

    &_valid {
      /* border: 1px solid green; */
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus#{&}:-webkit-autofill,
    &:-webkit-autofill::first-line,
    &::-internal-input-seggested {
      /* font: 1.25rem 'Product Sans', sans-serif !important; */ // рофланебало от разрабов хрома - захардкодили стили фонта ради безопасности - https://bugs.chromium.org/p/chromium/issues/detail?id=953689
      color: #102807;
      -webkit-text-fill-color: #102807;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 174, 255, 0.04) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(0, 174, 255, 0.03) 100%
      );
    }
    &::-webkit-inner-spin-button {
      display: none !important;
    }
  }

  &__password,
  &__password-off {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1.25rem;
  }
  &__password_active {
    display: flex;
  }
  &__password-off_active {
    display: flex;
  }

  &_fontSize_14 &__native {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;

    &::placeholder {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }

  &_color_default &__native {
    background: #fff;
    border: 1px solid #cbe3c2;
    color: #102807;

    &::placeholder {
      color: #a6b9a3;
    }
  }
  &_color_light &__native {
    background: #1d3156;
    border-radius: 0.3125rem;
    border: 1px solid #1d3156;
    color: white;

    &::placeholder {
      color: white;
    }
  }

  &__measurement {
    position: absolute;
    right: 1.25rem;
    font-weight: 500;
    font-size: 1rem;
    text-align: right;
    color: #102807;
  }
}
