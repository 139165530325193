.slots-history-item-mobile {
  background: #ffffff;

  &__top {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px 10px 10px;
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    background: #EBF9E6;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }
  &__item-title {
    margin: 0 0 4px 0;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #A6B9A3;

    &_end {
      text-align: end;
    }
  }
  &__item-value {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #102807;
  }
  &__status {
    display: flex;
    flex-direction: column;
  }
  &__lose {
    margin: auto 0 0 0;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: right;
    color: #42C903;
  }
  &__win {
    text-align: end;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #d94848;
  }
}
/*   &_color {
      &_red {
        background: #fcff6c;
      }
      &_green {
        background: #6cff83;
      }
    } */
