.bet-history-item-mobile {
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;

  &__dotted {
    background: linear-gradient(to right, #fff 50%, #d6d6d6 50%);
    background-size: 15px 1px;
    color: #acb2ca;
    width: 100%;
    height: 1px;
  }

  &__block1 {
    height: 49px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding: 10px 10px;
  }
  &_express &__block1 {
    grid-template-columns: 1fr 1fr 1fr min-content;
  }
  &__block1-item,
  &__block1-item2 {
    display: flex;
    flex-direction: column;
  }
  &__block1-item2 {
    justify-self: flex-end;
  }
  &__block1-item-title {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 12px;
    color: #8d8d8d;
  }
  &__block1-item-value {
    font-weight: 700;
    font-size: 14px;
    color: #071728;
  }
  &__arrow {
    width: 1.875rem;
    transform: scale(-1);

    &_active {
      transform: scale(1);
    }
  }

  &__block2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  &__block2-item {
    display: flex;
    flex-direction: column;
  }
  &__block2-item-text1 {
    font-weight: 700;
    font-size: 10px;
    color: #8d8d8d;
  }
  &__block2-item-text2 {
    font-weight: 700;
    font-size: 14px;
    color: #071728;
  }
  &__block2-item-text3 {
    font-weight: 400;
    font-size: 11px;
    color: #071728;
  }

  &__block2-total {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-weight: bold;
    font-size: 14px;
  }

  &__block22 {
  }
  &__total {
    height: 3.3125rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10px;
    font-weight: 700;
    font-size: 14px;
    color: #071728;
  }
  &__coef {
    border-radius: 5px;
    max-height: 33px;
    margin-left: 10px;
    padding: 9px 16px;
    font-weight: 700;
    font-size: 12px;
    background: #e8f9e6;
    color: #071728;

    &_status_2 {
      background: #0d9252;
      color: #ffffff;
    }
    &_status_4 {
      background: #d31c32;
      color: #ffffff;
    }
    &_status_5 {
      background: #ffc700;
      color: #ffffff;
    }
  }

  &__block3 {
    height: 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px 5px 10px;
    background: #e8f9e6;
  }
  &__block3-item {
    display: flex;
    flex-direction: column;
  }
  &__block3-item-title {
    margin-bottom: 1px;
    font-weight: 500;
    font-size: 12px;
    color: #8d8d8d;
  }
  &__block3-item-value {
    font-weight: 400;
    font-size: 12px;
    color: #071728;
  }

  &__status {
    margin: auto 0 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #102807;
    &_type_2 {
      color: #0d9252;
    }
    &_type_4 {
      color: #d31c32;
    }
    &_type_5 {
      color: #ffc700;
    }
  }
}
