.create-user {
  width: 100%;

  &__content {
    width: 100%;
    padding: 0.625rem;
  }

  &__field {
    width: 100%;
    margin-bottom: 0.8rem;
  }
  &__field-title {
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #102807;
  }
  &__field-input {
    width: 100%;
    height: 40px;
  }

  &__button {
    height: 2.5rem;
    width: 100%;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }
}
