.transaction-history {
  &__content {
    width: 100%;
    padding: 1.3125rem 0.625rem 0.625rem 0.625rem;
    background: #CBE3C2;
  }

  &__fields-title {
    margin: 0 0 0.4375rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #55B169;
  }
  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3px 8px;
    margin: 0 0 0.4375rem 0;
  }
  &__field-title {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #102807;
  }
  &__field-input {
    width: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
    margin: 0 0 0.625rem 0;
  }
  &__button-text {
    font-weight: 600;
    font-size: 0.875rem;
    text-align: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #ffffff;
  }

  &__result {
    width: 100%;
  }
  &__result-header {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background: #EBF9E6;
  }

  &__row {
    height: 2.1875rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    margin: 0 0 1px 0;
    padding: 0 1.25rem;
    background: #ffffff;
  }

  &__td {
    overflow: auto;
    width: 100%;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #102807;

    &:not(:first-child) {
      text-align: center;
    }

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__totals {
    width: 100%;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2.5rem;
    background: #EBF9E6;
  }
  &__totals-withdrawal,
  &__totals-topUp {
    display: flex;
    align-items: center;
  }
  &__totals-withdrawal-title,
  &__totals-withdrawal-value,
  &__totals-topUp-title,
  &__totals-topUp-value {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #102807;
  }
  &__totals-withdrawal-title,
  &__totals-topUp-title {
    margin: 0 0.625rem 0 0;
  }
}
