.spinner-mobile {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: none;

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__loader {
    width: 80%;
    display: flex;
  }

  // &_active {
  //   display: flex;
  // }
  // &__text {
  //   margin-top: 1rem;
  //   text-align: center;
  // }

  // &_size_small &__loader {
  //   display: block;
  //   height: 4rem;
  //   margin: 0 auto;
  //   transition: 0.2s;
  // }

  // &_size_big &__loader {
  //   display: block;
  //   height: 10rem;
  //   transition: 0.2s;

  //   .spinner-mobile__arrow {
  //     width: 5rem;
  //     height: 5rem;
  //     min-width: 5rem;
  //     min-height: 5rem;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     &_color {
  //       &_white {
  //         transition: all 2s ease-in;
  //         animation: rotateWhiteArrow 4s linear infinite;
  //       }
  //       &_orange {
  //         animation: rotateOrangeArrow 4s linear infinite;
  //       }
  //     }
  //   }
  // }

  // &__arrow {
  //   width: 2rem;
  //   height: 2rem;
  //   min-width: 2rem;
  //   min-height: 2rem;
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   &_color {
  //     &_white {
  //       transition: all 2s ease-in;
  //       animation: rotateWhiteArrow 4s linear infinite;
  //     }
  //     &_orange {
  //       animation: rotateOrangeArrow 4s linear infinite;
  //     }
  //   }
  // }

  // &__first-face,
  // &__second-face {
  //   position: absolute;
  //   border-radius: 50%;
  //   border-style: solid;
  //   animation: animate 3s linear infinite;
  // }

  // &__first-face {
  //   width: 100%;
  //   height: 100%;
  //   color: gold;
  //   border-color: gold transparent transparent gold;
  //   border-width: 0.2rem 0.2rem 0rem 0rem;
  //   --deg: -45deg;
  //   animation-direction: normal;
  // }

  // &__second-face {
  //   width: 70%;
  //   height: 70%;
  //   color: lime;
  //   border-color: lime lime transparent transparent;
  //   border-width: 0.2rem 0rem 0rem 0.2rem;
  //   --deg: -135deg;
  //   animation-direction: reverse;
  // }

  // &__circle {
  //   position: absolute;
  //   width: 50%;
  //   height: 0.1em;
  //   top: 50%;
  //   left: 50%;
  //   background-color: transparent;
  //   transform: rotate(var(--deg));
  //   transform-origin: left;
  //   &::before {
  //     position: absolute;
  //     top: -0.5em;
  //     right: -0.5em;
  //     content: "";
  //     width: 1em;
  //     height: 1em;
  //     background-color: currentColor;
  //     border-radius: 50%;
  //     box-shadow: 0 0 2em, 0 0 4em, 0 0 6em, 0 0 8em, 0 0 10em, 0 0 0 0.5em rgba(255, 255, 0, 0.1);
  //   }
  // }
}

@keyframes animate {
  to {
    transform: rotate(1turn);
  }
}

@keyframes rotateWhiteArrow {
  0% {
    transform: translate(0, 100%);
  }
  15% {
    transform: translate(0, 0);
  }
  30% {
    transform: translate(100%, 0);
  }
  45% {
    transform: translate(100%, 100%);
  }
  60% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes rotateOrangeArrow {
  0% {
    transform: translate(100%, 0);
  }
  15% {
    transform: translate(100%, 100%);
  }
  30% {
    transform: translate(0, 100%);
  }
  45% {
    transform: translate(0, 0);
  }
  60% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}
