.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    width: 100%;
    height: 2.9rem;
    display: flex;
    align-items: center;
    padding-left: 0.625rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #55b169;
  }

  &__item {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 0 1px 0;
    background: #fff;
  }
  &__item-img {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    margin-right: 0.625rem;
  }
  &__item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #102807;
  }
  &__element-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    margin: 0 0 0 auto;
  }
}
