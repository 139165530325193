.button {
  user-select: none;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border: none;
  outline: none;
  user-select: none;
  font-weight: 700;
  font-size: 0.8735rem;
  line-height: 1rem;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  white-space: nowrap;

  &:active {
    transform: translateY(2px);
  }

  &_borderRadius {
    &_default {
      // border-radius: 0.625rem;
    }
    &_15 {
      border-radius: 0.9375rem;
    }
    &_5 {
      border-radius: 0.3125rem;
    }
  }
  &_padding {
    &_default {
      padding: 0 1.25rem;
    }
  }

  &_color {
    &_default {
      background: #42C903;
    }
    &_white {
      background: #ffffff;
      border: 1px solid #CBE3C2;
    }
    &_red {
      background: #d94848;
    }
    &_light-blue {
      background: #42C903;
      opacity: 0.4;
    }
  }
  &:hover {
  }
  &:hover#{&}_color {
    &_default {
    }
  }

  &_disabled {
    cursor: not-allowed;
    background: #42C903;
    filter: brightness(0.8);
  }
}
