.locale {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    background: #fff;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    color: #102807;
    padding-left: 0.7rem;
    box-sizing: border-box;
    font-weight: 400;
    margin-bottom: 8px;
    justify-content: center;
  }

  &__item {
    width: 100%;
    height: 3.125rem;
    display: flex;
    align-items: center;
    padding-left: 0.625rem;
    margin: 0 0 1px 0;
    background: #fff;
  }
  &__item_active {
    background: #42C903;
  }

  &__item-flag {
    width: 1.4rem;
    margin-right: 0.5rem;
  }
  &__item-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #102807;
  }
  &__item_active &__item-text {
    color: #ffffff;
  }

  &__bottom {
    width: 100%;
    padding: 1.3125rem 0.625rem;
  }
  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}
