.change-pas {
  position: relative;

  &__back-block {
  }
  &__main {
    position: relative;
    width: 100%;
    background: #CBE3C2;
    padding: 0.625rem;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  &__item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 0.4375rem;
  }
  &__item-title {
    width: 100%;
    margin: 0 0 3px 0;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #102807;
  }
  &__item-field {
    widows: 100%;
    height: 2.5rem;
  }

  &__button {
    width: 100%;
    height: 2.5rem;
  }
  &__button-text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
}
