.notification {
  z-index: 100;
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1rem;
  padding: 0.8125rem;
  background: #ffffff;
  border: 1px solid #CBE3C2;
  box-shadow: 0 0.3125rem 0.3125rem rgba(46, 66, 98, 0.15);
  font-size: 1rem;
  color: #878787;
  animation: 0.7s ease init;

  &_type {
    &_default {
    }

    &_error {
    }

    &_success {
    }
  }
  /* &::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background: #102807;
    border-left: 1px solid #00d870;
    border-top: 1px solid #00d870;
    top: calc(-0.5rem - 1px);
    right: 1.3rem;
    transform: rotate(45deg);
  } */

  &_deleting {
    animation: 0.7s ease delete;
  }

  &__left {
    height: 100%;
    flex-shrink: 0;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__icon-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0.625rem auto 0;
  }
  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
  }
  &__text {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #102807;
  }
  &__cross-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 auto auto;
  }
  &__cross {
    width: 0.8125rem;
    height: 0.8125rem;
    display: flex;
  }

  @keyframes init {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes delete {
    0% {
      opacity: 1;
      height: 4rem;
    }
    70% {
      opacity: 0.5;
      transform: translateX(120%);
      height: 4rem;
    }
    100% {
      opacity: 0;
      transform: translateX(200%);
      height: 0;
    }
  }
}
