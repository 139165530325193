.menu {
  &__title {
    background: #42C903;
    width: 100%;
    height: 2.1875rem;
    display: flex;
    align-items: center;
    padding: 0 0.625rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: #ffffff;
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
